import request from '@/utils/request';
export var defaultSettingData = {
  id: 0,
  bankInformation: '',
  bidExpiryHours: 0,
  bidApprovalHours: 0,
  automatedCreditNoteGenerationDays: 0,
  expiryDays: 0,
  bookingAmountThreshold: 0,
  transactionPercent: 0,
  bookingPercent: 0,
  cancellationCharges: 0,
  bidStartTime: null,
  bidEndTime: null,
  referralAmount: 0,
  referrerAmount: 0,
  whatsappRecipients: []
};
export var getSettings = function getSettings(params) {
  return request({
    url: '/settings',
    method: 'get',
    params: params
  });
};
export var getSettingById = function getSettingById(id) {
  return request({
    url: "/settings/".concat(id),
    method: 'get'
  });
};
export var updateSetting = function updateSetting(id, data) {
  return request({
    url: "/settings/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteSetting = function deleteSetting(id) {
  return request({
    url: "/settings/".concat(id),
    method: 'delete'
  });
};
export var createSetting = function createSetting(data) {
  return request({
    url: '/settings/',
    method: 'post',
    data: data
  });
};