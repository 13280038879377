import request from '@/utils/request';
export var fileTransferHeaders = {};
export var defaultBidData = {
  id: 0,
  enabled: true,
  userId: undefined,
  productId: undefined,
  orderId: undefined,
  productsVariationId: undefined,
  status: '',
  bidQuantity: '',
  bidPrice: undefined,
  quotedPrice: undefined,
  rejectReason: '',
  user: {
    id: null
  },
  product: {
    id: null
  },
  productsVariation: {
    id: null,
    unitPrice: undefined,
    salePrice: undefined
  },
  order: {
    id: null
  }
};
export var getBids = function getBids(params) {
  return request({
    url: '/bids',
    method: 'get',
    params: params
  });
};
export var getBidsByUser = function getBidsByUser(user) {
  return request({
    url: "/bids?filter=user.fullName||$contL||".concat(user),
    method: 'get'
  });
};
export var getBidsByProduct = function getBidsByProduct(product) {
  return request({
    url: "/bids?filter=product.name||$contL||".concat(product),
    method: 'get'
  });
};
export var getBidById = function getBidById(id) {
  return request({
    url: "/bids/".concat(id),
    method: 'get'
  });
};
export var getBidInfo = function getBidInfo(id) {
  return request({
    url: "/bids/info/".concat(id),
    method: 'get'
  });
};
export var updateBid = function updateBid(id, data) {
  return request({
    url: "/bids/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteBid = function deleteBid(id) {
  return request({
    url: "/bids/".concat(id),
    method: 'delete'
  });
};
export var createBid = function createBid(data) {
  return request({
    url: '/bids/',
    method: 'post',
    data: data
  });
};
export var bidCount = function bidCount(data) {
  return request({
    url: '/bids/count',
    method: 'post',
    data: data
  });
};