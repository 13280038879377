import "core-js/modules/es.date.to-string";
import "core-js/modules/es.math.trunc";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/web.timers";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  filters: {
    twoDigits: function twoDigits(value) {
      if (value.toString().length <= 1) {
        return '0' + value.toString();
      }

      return value.toString();
    }
  },
  props: {
    date: null
  },
  data: function data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      event: this.date,
      finish: false
    };
  },
  computed: {
    secondCount: function secondCount() {
      return this.calculatedDate - this.now;
    },
    calculatedDate: function calculatedDate() {
      return Math.trunc(Date.parse(this.event) / 1000);
    },
    seconds: function seconds() {
      if (this.secondCount < 0) return 0;
      return this.secondCount % 60;
    },
    minutes: function minutes() {
      if (this.secondCount < 0) return 0;
      return Math.trunc(this.secondCount / 60) % 60;
    },
    hours: function hours() {
      if (this.secondCount < 0) return 0;
      return Math.trunc(this.secondCount / 60 / 60) % 24;
    },
    days: function days() {
      if (this.secondCount < 0) return 0;
      return Math.trunc(this.secondCount / 60 / 60 / 24);
    }
  },
  mounted: function mounted() {
    var _this = this;

    var _self = this;

    window.setInterval(function () {
      _this.now = Math.trunc(new Date().getTime() / 1000);

      if (!_this.finish && _this.calculatedDate - _this.now <= 0) {
        _self.finish = true;

        _self.$emit('onFinish');
      }
    }, 1000);
  }
};